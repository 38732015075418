<template>
    <div>
        <nav>
            <div>
                <router-link to="/"><i class="fal fa-arrow-left"></i> Назад</router-link>
            </div>
            <div>
                <h4>{{ getName }}</h4>
                <router-link :to="'/category/settings/' + $route.params.name + '/' + $route.params.id" class="edit" title="Редактировать"><i class="far fa-cog"></i></router-link>
                <a href="/delete" @click.prevent="deleteCategory($route.params.name, $route.params.id)" class="edit" title="Удалить"><i class="far fa-trash"></i></a>
            </div>
            <Calendar v-if="!isMobileMenu" />
        </nav>
        <transition name="fade" mode="out-in">
            <router-view />
        </transition>
    </div>
</template>

<script>
import Calendar from '@/components/Calendar';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    name: 'category',
    components: {
        Calendar
    },
    beforeMount() {
        if (!this.$route.params.id) return this.$router.push('/');
    },
    computed: {
        ...mapGetters([
            "getAccounts",
            "getSpending",
            "getIncoming",
            "isMobileMenu"
        ]),
        getName() {
            let categoryId = Number(this.$route.params.id);
            let categoryName = this.$route.params.name;
            let category = { name: 'не задано' };

            this.OPERATIONS_FILTERED([ categoryName, categoryId ]);

            switch (categoryName) {
                case 'account':
                    category = this.getAccounts.filter( (category) => category.id === categoryId )[0];
                    break;
                case 'spend':
                    category = this.getSpending.filter( (category) => category.id === categoryId )[0];
                    break;
                case 'income':
                    category = this.getIncoming.filter( (category) => category.id === categoryId )[0];
            }

            return (category) ? category.name : 'не найдено';
        }
    },
    methods: {
        ...mapActions(["delCategory"]),
        ...mapMutations(["OPERATIONS_FILTERED"]),

        deleteCategory(name, id) {
            this.$showConfirmDialog({
                title: `Безвозвратно удалить «${ this.getName }»?`,
                description: `Операции с ${ (name === 'account') ? 'этим счетом' : 'этой категорией' } останутся,
                но вместо названия ${ (name === 'account') ? 'счета' : 'категории' } будет "-недоступно-".`,
                approvedCallback: async () => {
                    try {
                        await this.delCategory({name, id});
                        this.$router.push('/');
                    } catch (error) {
                        console.log(error);
                    }
                }
            });
        }
    }
}
</script>

<style scoped lang="scss">
    @import '@/assets/forms.scss';

    nav {
        border-bottom: $border;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
    }

    a {
        display: inline-flex;
        align-items: center;
        color: $text;
        text-transform: uppercase;
        font-size: 20px;
        i {
            margin-right: 15px;
        }
        &.edit {
            font-size: 16px;
            i {
                margin-right: 0;
                margin-left: 10px;
            }
        }
        &:hover, &.router-link-exact-active {
            text-decoration: none;
        }
    }

    h4 {
        display: inline-block;
        font-size: 20px;
        color: $text;
    }

    @media screen and (max-width: 1024px) {
        a {
            font-size: 16px;
            i {
                margin-right: 12px;
            }
        }
        h4 {
            font-size: 18px;
        }
    }

    @media screen and (max-width: 768px) {
        a {
            padding-bottom: 12px;
        }
        nav h4 {
            margin-right: 5px;
        }
    }

    @media screen and (max-width: 610px) {
        nav {
            padding: 0 15px;
        }
    }
</style>
